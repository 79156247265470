import React, { useEffect } from "react";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";
// import Button from "../btn/Button";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../Button/Button";
import store from "../../redux/store";
import { set_open } from "../../redux/slice/dataSlice";

const SmallModal = ({
  setIsOpen,
  title,
  del,
  onClick,
  bname = "DELETE",
  isOpen,
  header_right,
  children,
  okbtn,
  style,
  closeBtnHide = true,
  okBtnHide = true,
  okBtnText = "OK",
  bgColor = "",
  preference = 10,
  cname,
  onCancle,
  ok_btn_click,
}) => {
  return (
    <>
      <div
        className={styles.darkBG}
        style={{ zIndex: preference }}
        onClick={() =>store.dispatch(set_open(false))}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={styles.smallcentered}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1,
              delay: 0.2,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            style={{...style, zIndex:11}}
          >
            <div className={styles.modal}>
              {/* <div className={styles.modalHeader}>
                <h5 className={styles.heading}>{title}</h5>
                {header_right}
                {closeBtnHide && (
                  <button
                    className={styles.closeBtn}
                    onClick={() => {
                      setIsOpen(false);
                      if (onCancle) {
                        onCancle();
                      }
                    }}
                  >
                    <RiCloseLine size={25} color="#2c3e50" />
                  </button>
                )}
              </div> */}
              <div
                style={{
                  boxShadow: "0px 0px 4px 0px #EEEEEE3D",
                }}
                className={`${styles.modalContent} bg-light-theme3 dark:bg-dark-theme3 rounded-[8px]  `}

                // style={{ backgroundColor: bgColor }}
              >
                <div className={styles.modalmess}>{children}</div>

                {/* {okbtn ? (
                  <>
                    {okBtnHide && (
                      <div
                        className={styles.actionsContainer1}
                        style={{ marginTop: 15 }}
                      >
                        <Button
                          className={styles.deleteBtn}
                          onClick={() => {
                            if (ok_btn_click) {
                              ok_btn_click();
                            } else {
                              setIsOpen(false);
                            }
                          }}
                          title={okBtnText}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    className={styles.actionsContainer}
                    style={{ marginTop: 15 }}
                  >
                    <Button
                      className={styles.deleteBtn}
                      onClick={() => setIsOpen(false)}
                      title={cname || "CANCEL"}
                    />
                    {bname?.length > 0 && (
                      <Button
                        className={styles.deleteBtn}
                        onClick={onClick}
                        title={bname}
                      />
                    )}
                  </div>
                )} */}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default SmallModal;
