import { FaAngleRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "../../redux/store";
import { set_open, set_open_modal } from "../../redux/slice/dataSlice";

export const LogoMenu = ({ menu, setSubMenu, subMenu, prefrance_click }) => {
  const navigate = useNavigate();

  return (
    <MenuItem menu={menu} pt={3}>
      <Submenu title={"Home"} />
      <Submenu
        set={setSubMenu}
        title={"Preferences"}
        icon={<FaAngleRight />}
        sub_data={subMenu}
        sub={
          <>
            <Submenu
              title={"Dark"}
              onClick={() => {
                prefrance_click("dark");
              }}
            />
            <Submenu
              title={"Light"}
              onClick={() => {
                prefrance_click("light");
              }}
            />
          </>
        }
      />
      <Submenu
        title={"Account Settings"}
        onClick={() => {
          // localStorage.clear();
          navigate("/dashboard/modal/profile");
          store.dispatch(set_open_modal(true));
        }}
      />
      <Submenu
        title={"Log Out"}
        onClick={() => {
          localStorage.clear();
          navigate("/");
          store.dispatch(set_open(true))
        }}
      />
    </MenuItem>
  );
};

export const PatientInfoMenu = ({
  menu,
  setSubMenu,
  subMenu,
  prefrance_click,
}) => {
  const navigate = useNavigate();

  return (
    <MenuItem menu={menu} pt={10} w="370px">
      <Submenu type={2} title={"John Doe"} />
      <Submenu type={2} title={"Age & Gender"} subTittel="56 Y, Male" />
      <Submenu
        title={"Reason For Study"}
        type={2}
        subTittel="Initial identification of lung nodules"
      />
      <Submenu title={"Patient’s class"} type={2} subTittel="Inpatient" />
      <Submenu
        title={"Location/ Institution"}
        type={2}
        subTittel="Renown healthcare"
      />
    </MenuItem>
  );
};

export const ScanInfoMenu = ({
  menu,
  setSubMenu,
  subMenu,
  prefrance_click,
}) => {
  const navigate = useNavigate();

  return (
    <MenuItem menu={menu} pt={10} w="350px">
      <Submenu type={2} title={"John Doe"} />
      <Submenu type={2} title={"Scan Type"} subTittel="CT Scan" />
      <Submenu title={"Scan Status"} type={2} subTittel="Scheduled" />

      <Submenu
        title={"Clinical Indications"}
        type={2}
        subTittel="Monitoring Known Lung Nodules"
      />
    </MenuItem>
  );
};

export const HistoryInfoMenu = ({
  menu,
  setSubMenu,
  subMenu,
  prefrance_click,
}) => {
  const navigate = useNavigate();

  return (
    <MenuItem menu={menu} pt={10} w="350px">
      <Submenu type={2} title={"No History Yet"} />
    </MenuItem>
  );
};

export const MenuItem = ({ children, pt, menu, w = "" }) => {
  return (
    <>
      {menu && (
        <div
          className={` bg-transparent absolute top-[90%] min-w-[300px]  pt-${pt}`}
          style={{ zIndex: 3, minWidth: w, paddingTop: `${pt * 4}px` }}
        >
          <ul
            style={{ boxShadow: " 0px 0px 8px 0px #0000003D" }}
            className="bg-light-theme3 dark:bg-dark-theme3 text-light-text dark:text-dark-text rounded-[8px] "
          >
            {children}
          </ul>
        </div>
      )}
    </>
  );
};

export const Submenu = ({
  title,
  icon,
  onClick,
  sub,
  set,
  sub_data,
  type = 1,
  subTittel,
}) => {
  const { theme } = useSelector((state) => state.data);

  return (
    <li
      className={`pt-3 pb-3 text-[15px] cursor-pointer pl-10 pr-10  flex  relative hover:bg-dark-active hover:text-dark-theme2  ${
        type == 2
          ? "flex-col justify-center items-start"
          : "justify-between  items-center"
      }`}
      style={{
        boxShadow:
          theme == "dark"
            ? "0px 0px 4px 0px #EEEEEE3D"
            : " 0px 0px 4px 0px #0000003D",
      }}
      onClick={onClick}
      onMouseEnter={() => {
        if (sub) {
          set(title);
        }
      }}
      onMouseLeave={() => {
        if (sub) {
          set("");
        }
      }}
    >
      <span className={`${type == 2 ? "text-[16px] font-bold" : ""}`}>
        {title}
      </span>
      {icon}
      {type == "2" && (
        <span className={`${type == 2 ? "text-[15px] " : ""}`}>
          {subTittel ? subTittel : ""}
        </span>
      )}
      {sub && sub_data == title && (
        <div
          className=" bg-transparent absolute left-[100%] top-0 min-w-[300px]  pl-3"
          style={{ zIndex: 4 }}
        >
          <ul
            style={{ boxShadow: " 0px 0px 8px 0px #0000003D" }}
            className="bg-light-theme3 dark:bg-dark-theme3 text-light-text dark:text-dark-text rounded-[8px] overflow-hidden "
          >
            {sub}
          </ul>
        </div>
      )}
    </li>
  );
};
