import React, { useState } from "react";
import { AuthCard } from "../../components/Cards/AuthCard";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { BsInfoCircle } from "react-icons/bs";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { validateEmail2, validatePassword } from "../../utils/Utils";
import { toast } from "react-toastify";

const SignUp = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  console.log("data ===", data);

  const signup = async () => {
    if (
      !data?.f_name ||
      !data?.l_name ||
      !data?.email ||
      !validateEmail2(data.email) ||
      !data?.password ||
      !validatePassword(data.password) ||
      !data?.c_password ||
      !validatePassword(data.c_password) ||
      data?.c_password != data?.password
    ) {
      setData({
        ...data,
        f_nameErr: !data.f_name ? "First name is required" : "",
        l_nameErr: !data.l_name ? "Last name is required" : "",
        emailErr: !data.email
          ? "Email is required"
          : !validateEmail2(data.email)
          ? "Please enter valid Email Address"
          : "",
        passwordErr: !data.password
          ? "Password is required"
          : !validatePassword(data.password)
          ? "Password must be at least 8 characters, including an uppercase letter, a number, and a special symbol."
          : "",
        c_passwordErr: !data.c_password
          ? "Confirm Password is required"
          : data.password != data?.c_password
          ? "Password and Confirm Password dosn't match"
          : "",
      });
      return;
    }

    try {
      setLoading(true);
      const obj = {
        email: data?.email,
        password: data?.password,
        first_name: data?.f_name,
        last_name: data?.l_name,
        confirm_password: data?.c_password,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      );

      const res = await response.json();
      setLoading(false);

      if (res.success) {
        toast.success(res.message);
        setData({});
        navigate("/login");
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <AuthCard page={"up"}>
      <div className="flex flex-col items-center mt-4 mb-4 w-full">
        <div>Sign Up To Get Started</div>
        <div className="flex flex-col items-center mt-4 w-full p-3 pl-10 pr-10 gap-4">
          <Input
            placeholder={"First Name"}
            value={data?.f_name}
            onChange={(e) => {
              const input = e.target.value.replace(/\s\s+/g, " ");
              setData({ ...data, f_name: input, f_nameErr: "" });
            }}
            error={data?.f_nameErr}
          />
          <Input
            placeholder={"Last Name"}
            value={data?.l_name}
            onChange={(e) => {
              const input = e.target.value.replace(/\s\s+/g, " ");
              setData({ ...data, l_name: input, l_nameErr: "" });
            }}
            error={data?.l_nameErr}
          />
          <Input
            placeholder={"Email"}
            value={data?.email}
            onChange={(e) => {
              const input = e.target.value.replace(/\s+/g, "");
              setData({ ...data, email: input, emailErr: "" });
            }}
            error={data?.emailErr}
          />
          <Input
            placeholder={"Password"}
            value={data?.password}
            onChange={(e) => {
              const input = e.target.value.replace(/\s+/g, "");
              setData({ ...data, password: input, passwordErr: "" });
            }}
            error={data?.passwordErr}
            type="password"
            icon={
              <Tooltip
                color={"#fff"}
                title={
                  <span style={{ color: "#1C2234" }}>
                    Password must be 8+ characters, with an uppercase, number,
                    and special symbol.
                  </span>
                }
              >
                <BsInfoCircle size={20} />
              </Tooltip>
            }
          />
          <Input
            value={data?.c_password}
            onChange={(e) => {
              const input = e.target.value.replace(/\s+/g, "");
              setData({
                ...data,
                c_password: input,
                c_passwordErr: "",
              });
            }}
            error={data?.c_passwordErr}
            placeholder={"Confirm Password"}
            type="password"
          />
          <Button
            title={"SIGN UP"}
            w={"100%"}
            loading={loading}
            onClick={signup}
            bg={"#3DC2FF"}
            tc={"#000"}
          />
          <div>
            Already have an account?{" "}
            <span
              className="text-[16px] font-bold cursor-pointer pl-1"
              onClick={() => navigate("/")}
            >
              SIGN IN
            </span>
          </div>
        </div>
      </div>
    </AuthCard>
  );
};

export default SignUp;
