import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthCard } from "../../components/Cards/AuthCard";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import {
  validateEmail2,
  validateMobile,
  validatePassword,
} from "../../utils/Utils";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  console.log("data ===", data);

  const login = async () => {
    if (
      !data?.email ||
      !data?.password ||
      !validateEmail2(data.email) ||
      !validatePassword(data.password)
    ) {
      setData({
        ...data,
        emailErr: !data.email
          ? "Email is required"
          : !validateEmail2(data.email)
          ? "Please enter valid Email Address"
          : "",
        passwordErr: !data.password
          ? "Password is required"
          : !validatePassword(data.password)
          ? "Password must be at least 8 characters, including an uppercase letter, a number, and a special symbol."
          : "",
      });
      return;
    }

    try {
      setLoading(true);
      const obj = {
        email: data?.email,
        password: data?.password,
      };

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });

      const res = await response.json();
      setLoading(false);

      if (res.success) {
        toast.success(res.message);
        setData({});
        localStorage.setItem("user", JSON.stringify(res?.data));
        navigate("/dashboard");
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthCard page={"in"}>
      <div className="flex flex-col items-center mt-4 mb-4 w-full">
        <div>Sign In To Continue</div>
        <div className="flex flex-col items-center mt-4 w-full p-3 pl-10 pr-10 gap-4">
          <Input
            placeholder={"Email"}
            value={data?.email}
            onChange={(e) => {
              const input = e.target.value.replace(/\s+/g, "");
              setData({ ...data, email: input, emailErr: "" });
            }}
            error={data?.emailErr}
          />
          <Input
            placeholder={"Password"}
            value={data?.password}
            onChange={(e) => {
              const input = e.target.value.replace(/\s+/g, "");
              setData({ ...data, password: input, passwordErr: "" });
            }}
            error={data?.passwordErr}
            pass={true}
            type="password"
          />
          <div
            className="w-full text-right text-[13px] cursor-pointer"
            onClick={() => navigate("/forgot")}
          >
            Forgot Password?
          </div>
          <Button
            // onClick={() => {
            //   localStorage.setItem("user", { id: "1112" });
            //   navigate("/dashboard");
            // }}
            loading={loading}
            onClick={login}
            title={"SIGN IN"}
            w={"100%"}
            bg={"#3DC2FF"}
            tc={"#000"}
          />
          <div>
            Do Not have an account?
            <span
              className="text-[16px] font-bold cursor-pointer pl-1"
              onClick={() => navigate("/signup")}
            >
              SIGN UP
            </span>
          </div>
        </div>
      </div>
    </AuthCard>
  );
};

export default Login;
