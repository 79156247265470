import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import {
  AddNoteIcon,
  DrawToolIcon,
  IconsCard,
  IconsCardRight,
  MousePoinIcon,
  OverlayIcon,
  PatientInfoIcon,
  ScaleIcon,
  ZoomIcon,
} from "../Icons/SvgIcons";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";
import store from "../../redux/store";
import {
  set_annotationMode,
  set_show_image,
} from "../../redux/slice/dataSlice";

const Layout = () => {
  const { theme, annotationMode, show_image, files_data } = useSelector(
    (state) => state.data
  );

  return (
    <div className="bg-light-theme1 dark:bg-dark-theme1 min-h-screen ">
      <Header />
      <div className="relative overflow-x-hidden">
        <div
          style={{
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            overflow: "hidden",
            zIndex: 2,
            boxShadow: "0px 4px 16px 0px #0000003D",
          }}
          className=" bg-light-theme3 dark:bg-dark-theme3 absolute top-[0px] left-0 w-[108px] max-w-90px flex gap-3 flex-col items-center "
        >
          <div
            style={{ boxShadow: " 0px 0px 4px 0px #0000003D" }}
            className="bg-light-theme2 dark:bg-dark-theme2 h-[30px]  w-[108px] max-w-90px flex justify-center items-center"
          >
            <div className="bg-[#E6E7E8] dark:bg-dark-theme3 h-[4px]  w-[50px] rounded-md "></div>
          </div>
          <div className="flex flex-col gap-3 mb-3 reduce_scale_9">
            {files_data.map((v, i) => (
              <IconsCard
                active={i == show_image}
                key={i}
                bg={"bg-light-theme1 dark:bg-dark-theme1"}
                onClick={() => store.dispatch(set_show_image(i))}
              >
                <span
                  className={`${
                    false
                      ? "text-light-text2 dark:text-dark-text2"
                      : "text-light-text dark:text-dark-text"
                  } font-semibold text-[20px] `}
                >
                  {String.fromCharCode(97 + i)?.toUpperCase()}{" "}
                </span>
              </IconsCard>
            ))}
          </div>
        </div>
        <div style={{ height: "calc(100vh - 140px)" }}>
          <Outlet />
        </div>
        <Draggable>
          <div
            style={{
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
              overflow: "hidden",
              zIndex: 1,
            }}
            className=" bg-light-theme1 dark:bg-dark-theme1 absolute top-0 right-0 w-[100px] max-w-90px  flex flex-col items-center "
          >
            <div className="bg-[#E6E7E8] dark:bg-dark-theme3 h-[30px]  w-[100px] max-w-90px  flex justify-center items-center "></div>
            <div className="flex flex-col mb-3 w-[100px] max-w-90px ">
              <IconsCardRight
                active={annotationMode == "polygon"}
                onClick={() =>
                  store.dispatch(
                    set_annotationMode(
                      annotationMode == "polygon" ? "" : "polygon"
                    )
                  )
                }
                theme={theme}
                bg={"bg-light-theme2 dark:bg-dark-theme2 "}
              >
                <MousePoinIcon
                  theme={theme}
                  active={annotationMode == "polygon"}
                />
              </IconsCardRight>
              <IconsCardRight
                active={annotationMode == "ruler"}
                onClick={() =>
                  store.dispatch(
                    set_annotationMode(annotationMode == "ruler" ? "" : "ruler")
                  )
                }
                theme={theme}
                bg={"bg-light-theme2 dark:bg-dark-theme2 "}
              >
                <DrawToolIcon
                  theme={theme}
                  active={annotationMode == "ruler"}
                />
              </IconsCardRight>
              <IconsCardRight
                active={annotationMode == "area"}
                onClick={() =>
                  store.dispatch(
                    set_annotationMode(annotationMode == "area" ? "" : "area")
                  )
                }
                theme={theme}
                bg={"bg-light-theme2 dark:bg-dark-theme2 "}
              >
                <AddNoteIcon active={annotationMode == "area"} theme={theme} />
              </IconsCardRight>
              <IconsCardRight
                theme={theme}
                bg={"bg-light-theme2 dark:bg-dark-theme2 "}
              >
                <ScaleIcon theme={theme} />
              </IconsCardRight>
              <IconsCardRight
                theme={theme}
                bg={"bg-light-theme2 dark:bg-dark-theme2 "}
              >
                <ZoomIcon theme={theme} />
              </IconsCardRight>
              <IconsCardRight
                theme={theme}
                bg={"bg-light-theme2 dark:bg-dark-theme2 "}
                active={annotationMode == "overlay"}
                onClick={() =>
                  store.dispatch(
                    set_annotationMode(
                      annotationMode == "overlay" ? "" : "overlay"
                    )
                  )
                }
              >
                <OverlayIcon theme={theme} />
              </IconsCardRight>
            </div>
          </div>
        </Draggable>
      </div>
      <div className="w-full h-[40px] absolute bottom-0  bg-light-theme2 dark:bg-dark-theme2"></div>
    </div>
  );
};

export default Layout;
