import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../../assets/IM-Logo.svg";

const AuthLayout = () => {
  return (
    <div className="h-[100vh] max-h-[100vh] w-full auth_bg flex items-center flex-col gap-10 overflow-y-scroll">
      <div className="mt-16 reduce_scale_9 ">
        <img src={logo} alt="logo" />
      </div>
      <div className="max-w-[450px] w-full reduce_scale_9">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
