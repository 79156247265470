import React, { useState } from "react";
import InsightViewer, { useImage } from "@lunit/insight-viewer";
import { AnnotationOverlay } from "@lunit/insight-viewer/annotation";
import { useSelector } from "react-redux";

import dcmFile from "../../assets/case1_008.dcm";
// const MOCK_IMAGE =
//   "wadouri:https://static.lunit.io/fixtures/dcm-files/series/CT000002.dcm";

export default function DiconViewer({
  annotations,
  setAnnotations,
  url,
  overlay_url,
}) {
  // const data = "https://insightsmedical.pmpframe.com/api/uploads/01eVNnmulr.dcm"
  const MOCK_IMAGE = `wadouri:${url}`;
  const { image } = useImage({ wadouri: MOCK_IMAGE });

  console.log("url ====", url);

  const { annotationMode } = useSelector((state) => state.data);

  // return (
  //       <InsightViewer
  //       image={image}
  //       style={{ backgroundColor: "#10141F", objectFit: "cover" }}
  //     >
  //       <AnnotationOverlay
  //         isDrawing={true}
  //         mode={annotationMode}
  //         annotations={annotations}
  //         onChange={setAnnotations}
  //       />
  //     </InsightViewer>
  // );

  return isImageFile(url) ? (
    // Render <img> for .png, .jpg, .jpeg
    <div className="flex justify-center items-center mt-9">
      {annotationMode == "overlay" ? (
        <img
          src={overlay_url}
          alt="Image"
          style={{
            backgroundColor: "#10141F",
            objectFit: "cover",
            width: "800px",
            height: "auto",
          }}
        />
      ) : (
        <img
          src={url}
          alt="Image"
          style={{
            backgroundColor: "#10141F",
            objectFit: "cover",
            width: "800px",
            height: "auto",
          }}
        />
      )}
    </div>
  ) : (
    <InsightViewer
      image={image}
      style={{ backgroundColor: "#10141F", objectFit: "cover" }}
    >
      <AnnotationOverlay
        isDrawing={true}
        mode={annotationMode}
        annotations={annotations}
        onChange={setAnnotations}
      />
    </InsightViewer>
  );
}

const isImageFile = (fileName) => {
  const validExtensions = [".png", ".jpg", ".jpeg"];
  return validExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
};
