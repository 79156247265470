import React, { useState } from "react";
// import OTPInput from 'react-otp-input';
import OtpInput from "react-otp-input";

export const OTPInputCom = ({ num = 5 }) => {
  const [code, setCode] = useState("");
  const handleChange = (code) => setCode(code);

  return (
    <div
      style={{
        boxShadow: " 0px 4px 4px 0px #0000001F",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <OtpInput
        style={{
          borderRadius: "8px",
        }}
        value={code}
        onChange={setCode}
        numInputs={num}
        placeholder={"-----"}
        renderInput={(props) => <input {...props} />}
        inputStyle={{
          border: "1px solid transparent",
          width: "54px",
          boxShadow: " 0px 4px 4px 0px #0000001F",
          height: "54px",
          fontSize: "16px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue",
          outline: "none",
        }}
        focusStyle={{
          border: "1px solid #CFD3DB",
          outline: "none",
        }}
      />
    </div>
  );
};
