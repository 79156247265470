import React, { useEffect } from "react";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";
// import Button from "../btn/Button";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../Button/Button";
import { AuthCard } from "../Cards/AuthCard";
import store from "../../redux/store";
import { set_open_modal } from "../../redux/slice/dataSlice";
import { useNavigate } from "react-router-dom";

const Modal = ({
  setIsOpen,
  title,
  del,
  body,
  onClick,
  bname,
  isOpen,
  header_right,
  width,
  hideClose = false,
  onCancel,
  bg = "",
  style,
  preference = 4,
  animation,
}) => {
  const navigate = useNavigate();

  console.log("animation ===",animation);
  

  return (
    <>
      <div
        className={styles.darkBG}
        style={{ zIndex: preference }}
        onClick={() => store.dispatch(set_open_modal(false))}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={styles.centered}
            initial={{ opacity: animation ? 0 : "" }}
            animate={{ opacity: animation ? 1 : "" }}
            transition={
              animation
                ? {
                    duration: 1,
                    delay: 0.2,
                    ease: [0, 0.71, 0.2, 1.01],
                  }
                : {}
            }
            style={{ maxHeight: style ? "" : width, ...style }}
          >
            <AuthCard
              naviClick={() => {
                navigate("/dashboard");
                store.dispatch(set_open_modal(false));
              }}
              other_title={"Account Settings"}
            >
              {body}
            </AuthCard>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Modal;
