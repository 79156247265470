export const base_url = "https://insightsmedical.pmpframe.com/api/";
// export const base_url = "https://radai.diasmart.in/"

export function validateEmail2(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
  return emailRegex.test(email);
}

// Function to validate mobile number
export function validateMobile(mobile) {
  const mobileRegex = /^[6-9]\d{9}$/; // 10-digit number, starting with 6-9
  return mobileRegex.test(mobile);
}

export function validatePassword(password) {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  }
  