import React from "react";
import { CiLock } from "react-icons/ci";
import { FaUserCircle } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { IoCardOutline } from "react-icons/io5";
import { MdLockOutline, MdOutlineNotifications } from "react-icons/md";
import { Outlet, useNavigate } from "react-router-dom";

const ProfileLayout = ({ children }) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-start overflow-hidden">
      <div
        className="flex flex-col flex-1 justify-center overflow-hidden "
        style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
      >
        <ListItem
          title={"Profile"}
          icon={<FaUserCircle size={20} />}
          onClick={() => navigate("/dashboard/modal/profile")}
        />
        <ListItem
          title={"Password"}
          icon={<MdLockOutline size={20} />}
          onClick={() => navigate("/dashboard/modal/password")}
        />
        <ListItem
          title={"Notifications"}
          onClick={() => navigate("/dashboard/modal/notifications")}
          icon={<MdOutlineNotifications size={20} />}
        />
        <ListItem
          title={"Payments"}
          icon={<IoCardOutline size={20} />}
          onClick={() => navigate("/dashboard/modal/payments")}
        />
        <ListItem
          title={"Help"}
          icon={<IoIosHelpCircleOutline size={20} />}
          onClick={() => navigate("/dashboard/modal/help")}
        />
      </div>

      <div className="flex-[2]">{children}</div>
    </div>
  );
};

const ListItem = ({ icon, title, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{ boxShadow: "0px 0px 8px 0px #00000029" }}
      className="pl-10 pr-3 pt-4 pb-4 cursor-pointer flex gap-5 items-center hover:bg-dark-active overflow-hidden"
    >
      <div>{icon}</div>
      <div>{title}</div>
    </div>
  );
};

export default ProfileLayout;
