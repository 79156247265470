import React from "react";
import { AuthCard } from "../../components/Cards/AuthCard";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { BsInfoCircle } from "react-icons/bs";
import { OTPInputCom } from "../../components/Input/OTPInputCom";
import { toast } from "react-toastify";

export const ForgotPass = () => {
  const navigate = useNavigate();

  return (
    <AuthCard navi={-1} other_title={"Forgot Password"}>
      <div className="flex flex-col items-center mt-4 mb-4 w-full">
        <div className="w-full text-left  mt-10 pl-10 pr-10">
          Please Enter Your Email Address To Receive A Verification Code
        </div>
        <div className="flex flex-col items-center mt-4 w-full p-3 pl-10 pr-10 gap-4">
          <Input label={"Email Address"} placeholder={"Email Address"} />
          <Button
            title={"Submit"}
            w={"100%"}
            bg={"#3DC2FF"}
            tc={"#000"}
            onClick={() => navigate("/submit-your-code")}
          />
        </div>
      </div>
    </AuthCard>
  );
};

export const SubmitYourCode = () => {
  const navigate = useNavigate();

  return (
    <AuthCard navi={-2} other_title={"Submit Your Code"}>
      <div className="flex flex-col items-center mt-4 mb-4 w-full">
        <div className="w-full text-left  mt-10 pl-10 pr-10">
          Please Enter The 5-Digit Code Sent To Your Email Address
        </div>
        <div className="flex flex-col items-center mt-4 w-full p-3 pl-10 pr-10 gap-4">
          <OTPInputCom />
          <div
            className="text-[12px] cursor-pointer"
            onClick={() => toast.success("Code Resend To Your Email Address")}
          >
            Resend Code
          </div>
          <Button
            title={"Confirm"}
            w={"100%"}
            bg={"#3DC2FF"}
            tc={"#000"}
            onClick={() => navigate("/create-new-pass")}
          />
          <div
            className="text-[12px] cursor-pointer underline"
            onClick={() => navigate(-1)}
          >
            Change Email
          </div>
        </div>
      </div>
    </AuthCard>
  );
};

export const CreateNewPassword = () => {
  const navigate = useNavigate();

  return (
    <AuthCard navi={-3} other_title={"Create New Password"}>
      <div className="flex flex-col items-center mt-4 mb-4 w-full">
        <div className="w-full text-left  mt-10 pl-10 pr-10">
          Your New Password Must Be Different From Previously Used Password
        </div>
        <div className="flex flex-col items-center mt-4 w-full p-3 pl-10 pr-10 gap-4">
          <Input
            placeholder={"Password"}
            label={"New Password"}
            type="password"
            icon={
              <Tooltip
                color={"#fff"}
                title={
                  // "Password must be 8+ characters, with an uppercase, number, and special symbol. "
                  <span style={{ color: "#1C2234" }}>
                    Password must be 8+ characters, with an uppercase, number,
                    and special symbol.
                  </span>
                }
              >
                <BsInfoCircle size={20} />
              </Tooltip>
            }
          />
          <Input
            placeholder={"Confirm Password"}
            label={"Confirm New Password"}
            type="password"
            pass={true}
          />
          <Button
            title={"Submit"}
            w={"100%"}
            bg={"#3DC2FF"}
            tc={"#000"}
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </AuthCard>
  );
};
