import React, { Children } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Outlet, useNavigate } from "react-router-dom";

export const AuthCard = ({
  children,
  page,
  other_title,
  navi = -1,
  naviClick,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="h-auto w-full bg-[#1C2234] flex flex-col "
      style={{
        borderTopLeftRadius: "40px",
        borderTopRightRadius: "8px",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
      }}
    >
      <div className="h-[75px] text-[#E6E7E8] flex justify-start items-center gap-5 pl-10">
        {page ? (
          <>
            <div
              onClick={() => navigate("/signup")}
              className={`${
                page == "up" ? "text-[20px]  font-extrabold" : "text-[16px]"
              } cursor-pointer`}
            >
              Sign Up
            </div>
            <div
              className={`${
                page == "in" ? "text-[20px]  font-extrabold" : "text-[16px]"
              } cursor-pointer`}
              onClick={() => navigate("/")}
            >
              Sign In
            </div>
          </>
        ) : (
          <div className="flex justify-start items-center gap-4">
            <FaArrowLeft
              cursor={"pointer"}
              size={24}
              onClick={() => {
                if (naviClick) {
                  naviClick();
                } else {
                  navigate(navi);
                }
              }}
            />
            <span className="text-[20px] font-bold">{other_title}</span>
          </div>
        )}
      </div>
      <div
        style={{
          borderTopLeftRadius: "40px",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
        className=" bg-white"
      >
        {children}
      </div>
    </div>
  );
};
