import React, { useState } from "react";
import logoDark from "../assets/IM-Logo.svg";
import logoLight from "../assets/im-logo-light.png";
import { useSelector } from "react-redux";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import {
  FinishIcon,
  HistoryIcon,
  IconsCard,
  PatientInfoIcon,
  RouteIcon,
  SaveIcon,
  ScanInfoIcon,
} from "./Icons/SvgIcons";
import store from "../redux/store";
import { get_theme_change } from "../redux/slice/dataSlice";
import { useNavigate } from "react-router-dom";
import {
  HistoryInfoMenu,
  LogoMenu,
  MenuItem,
  PatientInfoMenu,
  ScanInfoMenu,
  Submenu,
} from "./MenuItems/MenuItems";

const Header = () => {
  const { theme } = useSelector((state) => state.data);
  const [menu, setMenu] = useState(false);
  const [subMenu, setSubMenu] = useState("");

  const navigate = useNavigate();

  const prefrance_click = (type) => {
    store.dispatch(get_theme_change(type));
    setMenu(false);
    setSubMenu("");
  };

  return (
    <div
      // className="bg-light-theme2 dark:bg-dark-theme2 h-[100px] flex justify-between items-center pl-10 pr-10"
      // style={{
      //   boxShadow: "4px 0px 16px 0px #0000003D",
      // }}
      className="bg-light-theme2 dark:bg-dark-theme2 h-[100px] max-h-70px flex justify-between items-center pl-10 pr-10 "
      style={{
        boxShadow: "4px 0px 16px 0px #0000003D",
      }}
    >
      <div
        className="flex gap-6 justify-start items-center relative"
        onMouseLeave={() => setMenu("")}
      >
        <img
          src={theme === "light" ? logoLight : logoDark}
          alt="logo"
          onMouseEnter={() => setMenu("logo")}
          className="h-[60px] w-[265px] object-cover cursor-pointer"
        />
        <FaAngleDown
          size={25}
          className="text-light-text dark:text-dark-text cursor-pointer"
        />
        <LogoMenu
          prefrance_click={prefrance_click}
          menu={menu == "logo"}
          setSubMenu={setSubMenu}
          subMenu={subMenu}
        />
      </div>

      <div className="flex gap-3 justify-start items-center reduce_scale_9">
        <IconsCard
          onMouseLeave={() => setMenu("")}
          onMouseEnter={() => setMenu("patient")}
          bg={"bg-light-theme3 dark:bg-dark-theme3 reduce_scale_9 relative"}
        >
          <PatientInfoIcon theme={theme} />

          <PatientInfoMenu
            menu={menu == "patient"}
          />
        </IconsCard>
        <IconsCard
          onMouseLeave={() => setMenu("")}
          onMouseEnter={() => setMenu("scan")}
          bg={"bg-light-theme3 dark:bg-dark-theme3 reduce_scale_9 relative"}
        >
          <ScanInfoIcon theme={theme} />
          <ScanInfoMenu
            menu={menu == "scan"}
          />
        </IconsCard>
        <IconsCard 
         onMouseLeave={() => setMenu("")}
         onMouseEnter={() => setMenu("history")}
        bg={"bg-light-theme3 dark:bg-dark-theme3 reduce_scale_9 relative"}>
          <HistoryIcon theme={theme} />
          <HistoryInfoMenu
            menu={menu == "history"}
          />
        </IconsCard>
      </div>
      <div className="flex gap-3 justify-start items-center reduce_scale_9">
        <IconsCard bg={"bg-light-theme3 dark:bg-dark-theme3 reduce_scale_9"}>
          <SaveIcon theme={theme} />
        </IconsCard>
        <IconsCard bg={"bg-light-theme3 dark:bg-dark-theme3 reduce_scale_9"}>
          <RouteIcon theme={theme} />
        </IconsCard>
        <IconsCard
          active={true}
          bg={"bg-light-theme3 dark:bg-dark-theme3 reduce_scale_9"}
        >
          <FinishIcon active={true} theme={theme} />
        </IconsCard>
      </div>
    </div>
  );
};

export default Header;
