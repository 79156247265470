import React from "react";

const Button = ({
  title,
  icon,
  back,
  onClick,
  disable,
  righticon,
  lefticon,
  w = "100%",
  bg,
  tc,
  class_name
}) => {

  return (
    // <div
    //   className={`btn  ${
    //     disable ? "btn-info1" : back ? "btn-info" : "btn-success"
    //   }`}
    //   style={{
    //     cursor: disable && "default",
    //     backgroundColor: disable ? "gray" : "",
    //     border: disable ? "none" : "",
    //     width: width,
    //   }}
    //   role="button"
    //   onClick={() => {
    //     if (!disable && onClick) {
    //       onClick();
    //     }
    //   }}
    // >
    //   {icon}
    //   {title}
    // </div>
    <button
      onClick={onClick}
      className={`${class_name}  p-[10px] rounded-[8px] text-[18px] font-[600] flex gap-2 items-center justify-center `}
      style={{ backgroundColor: bg, color: tc, width: w }}
    >
      {lefticon}
      {title}
      {righticon}
    </button>
  );
};

export default Button;
