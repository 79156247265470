import React from "react";

// Dark Icons

import { ReactComponent as Icon1 } from "../../assets/icons/icon-patient-info.svg";
import { ReactComponent as Icon2 } from "../../assets/icons/icon-scan-info.svg";
import { ReactComponent as Icon3 } from "../../assets/icons/icon-history.svg";
import { ReactComponent as Icon4 } from "../../assets/icons/icon-save.svg";
import { ReactComponent as Icon5 } from "../../assets/icons/icon-route.svg";
import { ReactComponent as Icon6 } from "../../assets/icons/icon-finish.svg";


// Right Side icons Dark 

import { ReactComponent as Icon13 } from "../../assets/icons/icon-mouse-pointer.svg";
import { ReactComponent as Icon14 } from "../../assets/icons/icon-draw-tool.svg";
import { ReactComponent as Icon15 } from "../../assets/icons/icon-add-note.svg";
import { ReactComponent as Icon16 } from "../../assets/icons/icon-scale.svg";
import { ReactComponent as Icon17 } from "../../assets/icons/icon-zoom.svg";
import { ReactComponent as Icon18 } from "../../assets/icons/icon-overlay.svg";


// Light Icons

import { ReactComponent as Icon7 } from "../../assets/IconsLight/icon-patient-info-light.svg";
import { ReactComponent as Icon8 } from "../../assets/IconsLight/icon-scan-info-light.svg";
import { ReactComponent as Icon9 } from "../../assets/IconsLight/icon-history-light.svg";
import { ReactComponent as Icon10 } from "../../assets/IconsLight/icon-save-light.svg";
import { ReactComponent as Icon11 } from "../../assets/IconsLight/icon-route-light.svg";
import { ReactComponent as Icon12 } from "../../assets/IconsLight/icon-finish-light.svg";

// Right Side icons Light 

import { ReactComponent as Icon19 } from "../../assets/IconsLight/icon-pointer-light.svg";
import { ReactComponent as Icon20 } from "../../assets/IconsLight/icon-draw-tool-light.svg";
import { ReactComponent as Icon21} from "../../assets/IconsLight/icon-add-note-light.svg";
import { ReactComponent as Icon22} from "../../assets/IconsLight/icon-scale-light.svg";
import { ReactComponent as Icon23} from "../../assets/IconsLight/icon-zoom-light.svg";
import { ReactComponent as Icon24} from "../../assets/IconsLight/icon-overlay-light.svg";



export const IconsCard = ({ active = false, bg, children,onClick,onMouseLeave,onMouseEnter}) => {
  return (
    <div
      style={{
        borderTopRightRadius: active ? "4px" : "16px",
        borderTopLeftRadius: "4px",
        borderBottomRightRadius: active ? "16px" : "4px",
        borderBottomLeftRadius: "4px",
        boxShadow: "0px 0px 4px 0px #0000003D",
        backgroundColor: active ? "#3DC2FF" : "",
      }}
      className={`${bg} h-[60px] w-[60px] flex justify-center items-center cursor-pointer `}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};
export const IconsCardRight = ({ bg, children, theme,onClick,active }) => {
  return (
    <div
      style={{
        boxShadow:
          theme == "light"
            ? "0px 0px 4px 0px #0000003D"
            : "0px 0px 4px 0px #EEEEEE3D",
            backgroundColor: active ? "#3DC2FF" : "",
      }}
      className={`${bg} h-[100px] max-h-70px w-[100%] flex justify-center items-center cursor-pointer `}
      onClick={onClick}
    >
      {children}

    </div>
  );
};

export const PatientInfoIcon = ({ theme, active = false }) => {
  const Icon =
    theme === "dark" ? (active ? Icon7 : Icon1) : active ? Icon1 : Icon7;

  return <Icon className="h-6 w-6" />;
};

export const ScanInfoIcon = ({ theme, active = false }) => {
  const Icon =
    theme === "dark" ? (active ? Icon8 : Icon2) : active ? Icon2 : Icon8 ;
  return <Icon className="h-6 w-6" />;
};

export const HistoryIcon = ({ theme, active = false }) => {
  const Icon =
    theme === "dark" ? (active ? Icon9 : Icon3) : active ?  Icon3 : Icon9;
  return <Icon className="h-6 w-6" />;
};

export const SaveIcon = ({ theme, active = false }) => {
  const Icon =
    theme === "dark" ? (active ? Icon10 : Icon4) : active ?   Icon4 : Icon10;
  return <Icon className="h-6 w-6" />;
};

export const RouteIcon = ({ theme, active = false }) => {
  const Icon =
    theme === "dark" ? (active ? Icon11 : Icon5) : active ?  Icon5 : Icon11;
  return <Icon className="h-6 w-6" />;
};

export const FinishIcon = ({ theme, active = false }) => {
  const Icon =
    theme === "dark" ? (!active ? Icon6 : Icon12) : !active ? Icon12 : Icon6;
  return <Icon className="h-6 w-6" />;
};


export const MousePoinIcon = ({ theme, active = false,click }) => {
    const Icon =
      theme === "dark" ? (!active ? Icon13 : Icon19) : !active ? Icon19 : Icon13;;
    return <Icon onClick={click} className="h-6 w-6" />;
  };

  export const DrawToolIcon = ({ theme, active = false }) => {
    const Icon =
      theme === "dark" ? (!active ? Icon14 : Icon20) : !active ? Icon20 : Icon14;;
    return <Icon className="h-6 w-6" />;
  };

  export const AddNoteIcon = ({ theme, active = false }) => {
    const Icon =
      theme === "dark" ? (!active ? Icon15 : Icon21) : !active ? Icon21 : Icon15;;
    return <Icon className="h-6 w-6" />;
  };

  export const ScaleIcon = ({ theme, active = false }) => {
    const Icon =
      theme === "dark" ? (!active ? Icon16 : Icon22) : !active ? Icon22 : Icon16;;
    return <Icon className="h-6 w-6" />;
  };

  export const ZoomIcon = ({ theme, active = false }) => {
    const Icon =
      theme === "dark" ? (!active ? Icon17 : Icon23) : !active ? Icon23 : Icon17;;
    return <Icon className="h-6 w-6" />;
  };

  export const OverlayIcon = ({ theme, active = false }) => {
    const Icon =
      theme === "dark" ? (!active ? Icon18 : Icon24) : !active ? Icon24 : Icon18;;
    return <Icon className="h-6 w-6" />;
  };










// export const ScanInfoIcon = ({ theme, active = false }) => {
//   return theme == "dark" ? (
//     <Icon2 className="h-6 w-6 " />
//   ) : (
//     <Icon8 className="h-6 w-6 " />
//   );
// };

// export const HistoryIcon = ({ theme, active = false }) => {
//   return theme == "dark" ? (
//     <Icon3 className="h-6 w-6 " />
//   ) : (
//     <Icon9 className="h-6 w-6 " />
//   );
// };
// export const SaveIcon = ({ theme, active = false }) => {
//   return theme == "dark" ? (
//     <Icon4 className="h-6 w-6 " />
//   ) : (
//     <Icon10 className="h-6 w-6 " />
//   );
// };
// export const RouteIcon = ({ theme, active = false }) => {
//   return theme == "dark" ? (
//     <Icon5 className="h-6 w-6 " />
//   ) : (
//     <Icon11 className="h-6 w-6 " />
//   );
// };
// export const FinishIcon = ({ theme, active = false }) => {
//   return theme == "dark" ? (
//     <Icon6 className="h-6 w-6 " />
//   ) : (
//     <Icon12 className="h-6 w-6 " />
//   );
// };
