import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../store";
import { base_url } from "../../utils/Utils";
import { toast } from "react-toastify";

export const get_data_list = createAsyncThunk("data_slice", async (data) => {
  return [1, 2, 3, 4, 5];
});

export const get_theme_change = createAsyncThunk(
  "theme_change",
  async (data) => {
    return data;
  }
);
export const get_files = createAsyncThunk("files", async (data) => {
  store.dispatch(set_open(false));
  return data;
});
export const set_open = createAsyncThunk("set_open", async (data) => {
  return data;
});
export const set_open_modal = createAsyncThunk(
  "set_open_modal",
  async (data) => {
    return data;
  }
);
export const set_annotationMode = createAsyncThunk(
  "set_annotationMode",
  async (data) => {
    return data;
  }
);

// export const set_upload_images = createAsyncThunk(
//   "set_upload_images",
//   async (data) => {
//     try {
//       const body = new FormData();

//       body.append("action", "uploadimage");
//       data?.forEach((element) => {
//         body.append("images[]", element?.file);
//       });

//       const response = await fetch(base_url + "upload_file.php", {
//         method: "POST",
//         body: body,
//       });
//       const res = await response.json();
//       store.dispatch(get_files_list())
//       return res;
//     } catch (error) {
//       console.log(error);
//     }
//     return data;
//   }
// );
export const set_upload_images = createAsyncThunk(
  "set_upload_images",
  async (data, { dispatch }) => {
    try {
      const body = new FormData();
      // body.append("action", "uploadimage");

      // Append each file to the form data
      data.forEach((file) => {
        body.append("file", file); // file is directly accessed, no need for .file
      });

      console.log("data ==",data);
      

      // Upload the images
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "detect_nodule",
        {
          method: "POST",
          body: body,
        }
      );

      const res = await response.json();

      // Dispatch another action to get the list of files after upload
      // dispatch(get_files_list());

      return res;
    } catch (error) {
      console.log("Error uploading images:", error);
      throw error;
    }
  }
);
//

export const get_files_list = createAsyncThunk(
  "get_files_list",
  async (data) => {
    try {
      const body = new FormData();

      body.append("action", "list");

      const response = await fetch(base_url + "upload_file.php", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.log(error);
    }
    return data;
  }
);

export const set_show_image = createAsyncThunk(
  "set_show_image",
  async (data) => {
    return data;
  }
);
const dataSlice = createSlice({
  name: "list",
  initialState: {
    loading: false,
    data: [],
    theme: "dark",
    files: [],
    open: true,
    openmodal: false,
    do_animation: true,
    annotationMode: "polygon",
    files_data: [],
    files_loading: false,
    show_image: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(get_data_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.loading = false;
      state.data = data;
    });
    builder.addCase(get_data_list.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_data_list.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(get_theme_change.fulfilled, (state, action) => {
      const data = action.payload;
      state.theme = data;
    });
    builder.addCase(get_files.fulfilled, (state, action) => {
      const data = action.payload;
      state.files = data;
    });

    builder.addCase(set_open.fulfilled, (state, action) => {
      const data = action.payload;
      state.open = data;
    });
    builder.addCase(set_open_modal.fulfilled, (state, action) => {
      const data = action.payload;
      state.openmodal = data;
      state.do_animation = false;

      // if(state.openmodal){
      //   state.do_animation = false
      // } else if(!state.openmodal){
      //   state.do_animation = true
      // }
    });
    builder.addCase(set_annotationMode.fulfilled, (state, action) => {
      const data = action.payload;
      state.annotationMode = data;
    });
    builder.addCase(set_show_image.fulfilled, (state, action) => {
      const data = action.payload;
      state.show_image = data;
    });

    builder.addCase(set_upload_images.pending, (state, action) => {
      state.files_loading = true;
    });
    builder.addCase(set_upload_images.rejected, (state, action) => {
      state.files_loading = false;
    });

    builder.addCase(set_upload_images.fulfilled, (state, action) => {
      state.files_loading = false;
      const data = action.payload;
      console.log("data ----datasilce =====", data);

      if (data.success) {
        // state.files_data = data?.data || [];
        state.files_data = [data?.results] || [];
        state.open = false;
      } else if (data.success) {
        state.files_data = [];
        toast.error(data?.error)
      }
    });

    builder.addCase(get_files_list.rejected, (state, action) => {
      state.files_loading = false;
    });
    builder.addCase(get_files_list.fulfilled, (state, action) => {
      state.files_loading = false;
      const data = action.payload;
      console.log("data ----datasilce =====", data);

      if (data.success) {
        state.files_data = data?.data;
        state.open = false;
      } else if (data.success) {
        state.files_data = [];
      }
    });
  },
});

export default dataSlice.reducer;
